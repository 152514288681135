<template>
  <div class="aboutUs">
    <div class="bg">
      <div class="poster">
        <img src="../../assets/aboutBanner.png" alt="" />
      </div>
      <div class="item">
        <h2>公司简介</h2>
        <b-container class="introDuction">
          <p>
            广州市萌酷信息科技有限责任公司（以下简称萌酷科技）于2016年成立，广州市萌酷信息科技有限责任公司位于中国的南大门羊城广州，公司经过5年锤炼已经发展壮大。萌酷科技致力于为全国本科院校、高职高专提供VR虚拟仿真教学软件研发、AR教学资源研发、VR资源管理平台建设、微课制作等服务。
          </p>
          <p>
            萌酷科技秉持“客户第一、服务至上、产品极致”的经营理念，拥有专业的研发与商务团队，利用先进的3D仿真、VR/AR研发技术，使用“平台+资源+服务”三位一体的业务模式，为各院校提供虚拟仿真实验教学平台+资源建设全套解决方案。支持院校完成国家级虚拟仿真实验教学项目申报。
          </p>
        </b-container>
        <div class="centerH">
          <img src="../../assets/about02.png" alt="" />
        </div>
      </div>
      <div class="item advantageItem">
        <h2>企业优势</h2>
        <b-container class="advantage">
          <swiper :options="swiperOption" ref="mySwiper">
            <!-- slides -->
            <swiper-slide
              v-for="(aboutItem, aboutIndex) in aboutLists"
              :key="aboutIndex"
              @click="mouseEnter(aboutIndex)"
            >
              <div class="dot">
                <div class="dots">{{ aboutItem.num }}</div>
                <div class="title">{{ aboutItem.title }}</div>
                <div class="content">
                  {{ aboutItem.content }}
                </div>
                <div class="imgs">
                  <img :src="aboutItem.src" alt="" />
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" slot="button-prev">
            <img src="../../assets/prev.png" alt="" />
          </div>
          <div class="swiper-button-next" slot="button-next">
            <img src="../../assets/next.png" alt="" />
          </div>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import "swiper/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  data() {
    return {
      swiperOption: {
        observer: true,
        notNextTick: true,
        slidesPerView: 2.5, //一行显示3个
        spaceBetween: 75, //间隔
        freeMode: true,
        speed: 1000, //滑动速度
        slidesOffsetAfter: 780, //最后一个slide与右边界产生了偏移量
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          // //监听页面窗口变化 ---------------重要代码！
          // resize: function () {
          //   console.log();
          //   let swiper = this.$refs.mySwiper.swiper;
          //   console.log();
          //   if (document.body.clientWidth < 768) {
          //     // this.destroy();
          //     this.slidesPerView = 1.5;
          //     this.spaceBetween = 75; //间隔
          //     this.slidesOffsetAfter = 780;
          //     console.log(this, this.slidesPerView);
          //     // swiper.init();
          //     swiper.updated();
          //   }
          // },
        },
      },
      aboutLists: [
        {
          title: "技术门槛",
          content:
            "目前虚拟仿真现实发展至今，硬件已经没有技术壁垒与门槛，VR设备成本也已经降至平民级别。萌酷科技历经5年沉淀，积累了大量MR，VR，数字孪生的内容与解决方案经验。",
          src: require("../../assets/about001.png"),
          num: "01",
        },
        {
          title: "项目积累",
          content:
            "2016年开启虚拟现实实验室研究在虚拟现实实验室中沉淀了大量的案例。立足数字化、信息技术方向全面发展。基于前期发展沉淀的经验,立足于服务品质，务实创新推动虚拟仿真，信创产业方向的全面发展。",
          src: require("../../assets/about002.png"),
          num: "02",
        },
        {
          title: "人员成本",
          content:
            "依托广东工业大学数字化人才定向培养计划，成立了广东工业大学信息化实训基地，实现人才不断档，为公司长远发展奠定夯实基础。",
          src: require("../../assets/about003.png"),
          num: "03",
        },
        {
          title: "业务范围覆盖",
          content:
            "萌酷科技仿真的服务。产品稳定，经验丰富，建设多学科领域覆盖。提供医药学、生物、食品、建筑、土木、矿业、轻工、机械等多学科虚拟仿真产品，以及其他软件开发。",
          src: require("../../assets/about004.png"),
          num: "04",
        },
      ],
    };
  },
  methods: {
    mouseEnter(index) {
      this.$refs.mySwiper.swiper.activeIndex = index;
    },
  },
  components: {
    swiper,
    swiperSlide,
  },
  created() {
    var clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 768) {
      this.swiperOption.slidesPerView = 1.5;
      this.swiperOption.slidesOffsetAfter = 130;
    } else {
      this.swiperOption.slidesPerView = 2.5;
      this.swiperOption.slidesOffsetAfter = 780;
    }
  },
  mounted() {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0;
}
.bg {
  background: url("../../assets/aboutBg.png") no-repeat;
  background-size: cover;
}
.swiper-button-prev {
  left: -140px;
}
.swiper-button-prev,
.swiper-button-next {
  width: 65px;
  height: 65px;
  background-color: #ffffff;
  border-radius: 50%;
  text-align: center;
  img {
    display: block;
    width: 46px;
  }
  &::after {
    content: "";
  }
}
.swiper-button-next {
  right: -140px;
}
.swiper-container {
  padding: 20px 0 10px;
  &::after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 20px;
    border-top: 2px solid #d1d1d1;
  }
}
.swiper-slide-active {
  // margin-top: -2px;
  // border: 2px solid #3c8cff !important;
  // box-shadow: 0px 0px 10px 2px rgb(83 199 251 / 26%);
  // border-radius: 0 0 5px 5px;
}
.swiper-slide {
  border: 2px solid transparent;
  &:hover {
    border: 2px solid #3c8cff;
    box-shadow: 0px 0px 10px 2px rgb(83 199 251 / 26%);
    border-radius: 5px;
  }
  img {
    width: 100%;
  }
}
.aboutUs {
  // background-color: #fafafa;
  background-size: cover;
  box-sizing: border-box;
  margin-top: 80px;
  .poster {
    margin-bottom: 200px;
    max-width: 1920px;
    img {
      width: 100%;
    }
  }
  .item {
    margin-top: 100px;
    h2 {
      width: 160px;
      margin: 0 auto;
      line-height: 60px;
      font-size: 40px;
      color: #333333;
      position: relative;
      &::after {
        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 100px;
        border: 2px solid #3c8cff;
        height: 0;
        border-radius: 4px;
      }
    }
    .introDuction {
      margin-top: 128px;

      p {
        margin-bottom: 100px;
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Light;
        font-weight: 300;
        color: #666;
        line-height: 30px;
      }
    }
    .centerH {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .advantage {
      margin-top: 140px;
      position: relative;
      .hr {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0px;
        border-top: 2px solid #d1d1d1;
      }
      .dots {
        display: block;
        content: "";
        width: 32px;
        height: 32px;
        background: #3c8cff;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        z-index: 9;
        text-align: center;
        line-height: 32px;
        color: #ffffff;
      }
      .title {
        margin: 47px 0 35px;
        font-size: 30px;
        font-family: Source Han Sans CN, Source Han Sans CN-Normal;
        color: #333;
        text-align: center;
      }
      .content {
        margin-bottom: 75px;
        height: 96px;
        padding: 0 25px;
        font-size: 16px;
        font-weight: 300;
        color: #999999;
        line-height: 24px;
      }
    }
  }
  .advantageItem {
    padding-bottom: 250px;
  }
}
@media (max-width: 768px) {
  .swiper-slide {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #3c8cff;
    }
  }
  .swiper-slide {
    border: 1px solid #3c8cff;
    box-shadow: 0px 0px 10px 2px rgb(83 199 251 / 26%);
    border-radius: 5px;
    line-height: 14px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .aboutUs {
    width: 100%;
    margin-top: 60px;
    .poster {
      margin-bottom: 0px;
    }
    .item {
      padding: 0 20px;
      margin-top: 42px;
      h2 {
        width: 64px;
        line-height: 25px;
        font-size: 16px;
        &::after {
          width: 30px;
          border: 1px solid #3c8cff;
          border-radius: 1px;
        }
      }
      .introDuction {
        margin-top: 26px;
        p {
          text-indent: 2em;
          margin-bottom: 20px;
          text-align: justify;
          font-size: 10px;
          font-weight: 300;
          line-height: 24px;
        }
      }
      .centerH {
        display: none;
        width: 100%;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .advantage {
        margin-top: 20px;
        position: relative;
        .hr {
          border-top: 1px solid #d1d1d1;
        }
        .dots {
          width: 22px;
          height: 22px;
          line-height: 22px;
          font-size: 12px;
        }
        .title {
          margin: 20px 0 10px;
          font-size: 12px;
        }
        .content {
          margin-bottom: 25px;
          height: 80px;
          padding: 0 11px;
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
    .advantageItem {
      padding-bottom: 100px;
    }
  }
}
</style>